.navbar button[aria-expanded="true"] > span.close {
    display: inline;
}

.navbar button[aria-expanded="true"] > span.navbar-toggler-icon {
    display: none;
}

.navbar button[aria-expanded="false"] > span.close {
    display: none;
}


body {
    font-family: 'Inter', sans-serif;
    background: #F3F3F3;
}

.navbar-brand {
    font-size: 16px;
    font-weight: bold;
}

.gradient-content {
    font-weight: bolder;
    background: -webkit-linear-gradient(#0069CE, #70B9FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.not-gradient-content {
    font-weight: bolder;
    background: -webkit-linear-gradient(#0F0D1C, #0F0D1C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.info-title {
    font-size: 37px;
    font-weight: bolder;
    background: -webkit-linear-gradient(#0F0D1C, #0F0D1C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.step-title {
    font-size: 20px;
}

.step-content {
    font-size: 15px;
    color:#818087;
}

.what-title {
    font-size: 33px;
    font-weight: bolder;
}

.what-content {
    font-size: 21px;
}

.navbar{
    backdrop-filter: blur(40px)!important;
    background-color: #fffc!important;
}

.navbar:has(.show) {
    background: hsla(0, 0%, 100%, 1)!important;
}










/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 100vh;
    position: relative;
}

#hero:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .container {
    position: relative;
    /*padding-top: 74px;*/
    text-align: center;
}

#hero h1 span {
    color: #ffc451;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #hero {
        height: auto;
    }
    .slide-text{
        font-size:30px!important;
    }
}

.slide-text{
    font-size: 84px;
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    overflow: hidden;
}

.rc-slider-handle:active {
 box-shadow: none!important;
}

@media (max-width: 480px) {
    .rc-slider-handle{
        margin-top:-1vw!important;
    }

    .main-content{
        margin-top: 150px;
        height: 500px;
    }
}
